import React, {Fragment} from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

export const Skill = (props) => {
  const labels = {
    1: "E - Very poor",
    2: "E+ - Fairly poor",
    3: "D - Poor ",
    4: "D+ - Fairly good",
    5: "C - Alright",
    6: "C+ - Average",
    7: "B - Good",
    8: "B+ - Great",
    9: "A - Excellent",
    10: "A+ - Incredible",
  };

  const [value, setValue] = React.useState(props.defaultValue);
  const [hover, setHover] = React.useState(-1);

  return (
    <Fragment>
    <div className="skills__description">
      <i className="skills__icon">{props.skillsIcon}</i>
      <h4 className="skills__name">{props.skillName}</h4>
    </div>
    <div className="skills____rating" data-aos="zoom-in">
    <Box
          sx={{
            width: 200,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Rating
            name="customized-10"
            size="large"
            defaultValue={props.defaultValue}
            max={10}
            // readOnly={true}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </Box>
    </div>
    </Fragment>
  );
};
