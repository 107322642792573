import React, {Fragment, useState} from 'react'
import { Homevid } from '../Homevid'
import Navbar from '../Navbar'
import { About } from '../About'
import { Skills } from '../skills/Skills'

export const Top = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Fragment>
            <Homevid isOpen={isOpen}/>
            <Navbar />
            <About />
            <Skills isOpen={isOpen}/>
        </Fragment>
    )
}
