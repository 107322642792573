import React, { Fragment } from "react";
import { Skill } from "./Skill";
import { AiFillHtml5 } from "react-icons/ai";
import { DiCss3, DiReact, DiSass } from "react-icons/di";
import { FaNodeJs, FaPython, FaAngular } from "react-icons/fa";
import { SiFirebase } from "react-icons/si";
import {
  SiJavascript,
  SiJquery,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiAdobepremiere,
  SiMongodb,
} from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
// import { menuOpen } from '../Navbar';

export const Skills = () => {
  // if (!isOpen) {
  window.addEventListener("scroll", function () {
    const elementTarget = document.getElementById("section-homevid");
    const scrollTop = document.getElementsByClassName("scroll-top__btn")[0];
    // const scrollTopAfter = window.getComputedStyle(scrollTop, "::after");
    if (window.scrollY > elementTarget.offsetTop + elementTarget.offsetHeight) {
      document.getElementById("navbar").style.position = "fixed";
      document.getElementById("navbar").style.top = "0";
      document.getElementById("section-about").style.marginTop = "12rem";
      // document.getElementsByClassName("scroll-top")[0].style.transform = "rotate(180deg)";
      // document.getElementsByClassName("scroll-top")[0].style.transform = "rotate(initial)";
      scrollTop.style.setProperty("--upArrOpacity", "1")
      scrollTop.style.setProperty("--upArrVisibility", "visible")
      scrollTop.style.setProperty("--downArrOpacity", "0")
      scrollTop.style.setProperty("--downArrVisibility", "hidden")
      document.getElementById("scroll-top").style.opacity = "0.5"
      document.getElementById("scroll-top").addEventListener("mouseover", () => {
        document.getElementById("scroll-top").style.opacity = "1"
      })
      document.getElementById("scroll-top").addEventListener("mouseleave", () => {
        document.getElementById("scroll-top").style.opacity = "0.5"
      })
      scrollTop.href = "/#section-homevid"
    } else {
      document.getElementById("navbar").style.position = "initial";
      document.getElementById("scroll-top").style.opacity = "1"
      document.getElementById("scroll-top").addEventListener("mouseleave", () => {
        document.getElementById("scroll-top").style.opacity = "1"
      })
      scrollTop.style.setProperty("--upArrOpacity", "0")
      scrollTop.style.setProperty("--upArrVisibility", "hidden")
      scrollTop.style.setProperty("--downArrOpacity", "1")
      scrollTop.style.setProperty("--downArrVisibility", "visible")
      scrollTop.href = "/#section-about"
      // document.getElementsByClassName("scroll-top")[0].style.transform = "rotate(0)";
    }
  });
  // }
  return (
    <Fragment>
      <h2 className="heading heading-secondary skills__heading" data-aos="zoom-in">📋 Skills</h2>
      <div className="skills" id="section-skills">
        <div className="skills__ratings--1">
          <h4 className="heading heading-subheading skills__header">
            Languages
          </h4>
          <Skill
            skillsIcon={<AiFillHtml5 />}
            skillName="HTML"
            defaultValue={9}
          />
          <Skill skillsIcon={<DiCss3 />} skillName="CSS" defaultValue={9} />
          <Skill
            skillsIcon={<FaNodeJs />}
            skillName="Node.js"
            defaultValue={6}
          />
          <Skill
            skillsIcon={<FaPython />}
            skillName="Python"
            defaultValue={8}
          />
          <Skill
            skillsIcon={<SiJavascript />}
            skillName="Javascript"
            defaultValue={7}
          />
          <Skill
            skillsIcon={<DiSass />}
            skillName="SASS/SCSS"
            defaultValue={7}
          />
        </div>
        {/* <div className="skills__separator">
                <BiCodeAlt />
            </div> */}
        <div className="skills__ratings--2">
          <h4 className="heading heading-subheading skills__header">
            Frameworks/Libraries
          </h4>
          <Skill
            skillsIcon={<SiJquery />}
            skillName="jQuery"
            defaultValue={6}
          />
          <Skill skillsIcon={<DiReact />} skillName="React" defaultValue={7} />
          <Skill
            skillsIcon={<FaAngular />}
            skillName="Angular"
            defaultValue={5}
          />
          <Skill
            skillsIcon={<SiMongodb />}
            skillName="MongoDB"
            defaultValue={7}
          />
          <Skill
            skillsIcon={<SiFirebase />}
            skillName="Firebase"
            defaultValue={7}
          />
        </div>

        <div className="skills__ratings--3">
          <h4 className="heading heading-subheading skills__header">Other</h4>
          <Skill
            skillsIcon={<SiAdobephotoshop />}
            skillName="Adobe Photoshop"
            defaultValue={5}
          />
          <Skill
            skillsIcon={<SiAdobeillustrator />}
            skillName="Adobe Illustrator"
            defaultValue={6}
          />
          <Skill
            skillsIcon={<SiAdobepremiere />}
            skillName="Adobe Premiere Pro"
            defaultValue={6}
          />
        </div>
      </div>
    </Fragment>
  );
};
