import { Project } from "./Project";
import React, { Fragment } from "react";
import { Modal } from "./modal/PortfolioModal";
// import { ModalReference } from "./modal/ModalReference";
import { AiFillGithub } from "react-icons/ai";

export const Portfolio = () => {
    const githubIcon = <AiFillGithub />
  return (
    <div className="portfolio" id="section-portfolio">
      <h2 className="heading heading-secondary" data-aos="zoom-in">💼 Portfolio</h2>
      <div className="portfolio__projects">
        <Project
          projectName="Github Finder"
          project="github-finder"
          languages="React/CSS"
          cardNum="1"
        />
        <Project
          projectName="To Do List"
          project="todo-list"
          languages="MongoDB/EJS"
          cardNum="2"
        />

        <Project
          projectName="Tindog"
          project="tindog"
          languages="Bootstrap/CSS"
          cardNum="3"
        />
        <Project
          projectName="Mailchimp Signup"
          project="mailchimp-signup"
          languages="Mailchimp/Node"
          cardNum="4"
        />
        <Project
          projectName="Minimalist Blog"
          project="minimalist-blog"
          languages="MongoDB/EJS"
          cardNum="5"
        />
        <Project
          projectName="Natours"
          project="natours"
          languages="HTML/SCSS"
          cardNum="6"
        />
        <Project
          projectName="Art Gallery"
          project="art-gallery"
          languages="React/Animate on scroll (AOS)"
          cardNum="7"
        />
        <Project />
        <Project />
        </div>
        <Modal
          image1="https://i.ibb.co/fxghdBF/github-finder.png"
          image2="https://i.ibb.co/Y2RYcmN/Screen-Shot-2021-10-05-at-7-31-22-pm.png"
          project="github-finder"
          projectName="Github Finder"
          languages="css/react"
          description="Github finder is a website which fetches users registered on github through github's API. I use both functional and class-based react components."
          skills={["Accessing data through API", "Grid layout", "Loading animation"]}
          link1="google.com"
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/j5wHZfc/todolist-home.png"
          image2="https://i.ibb.co/0Q5TFxB/todolist-work.png" 
          projectName="To Do List"
          project="todo-list"
          languages="MongoDB/EJS"
          description="This todo list dynamically adds new lists based on the website URL params. It stores users' lists on MongoDB atlas."
          skills={["Storing data in DB", "Styling inputs", "Using JS in EJS"]}
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/rf83NRV/tindog1.png"
          image2="https://i.ibb.co/VB35mLm/tindog2.png"
          projectName="Tindog"
          project="tindog"
          languages="Bootstrap/CSS"
          description="Tindog is a website which uses a styling property named bootstrap to use popular components already styled."
          skills={["Grids using Bootstrap", "Positioning correctly"]}
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/Xznbd5p/signup1.png"
          image2="https://i.ibb.co/fr1qrG5/signup2.png"
          projectName="Mailchimp Signup"
          project="mailchimp-signup"
          languages="Mailchimp/Node"
          description="Mailchimp signup allows for people to recieve emails based on whether they have registered on my mailchimp DB."
          skills={["Posting to Mailchimp API", "Creating signup form", "Redirecting to pages using Node backend"]}
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/mcYz2tX/blog1-1.png"
          image2="https://i.ibb.co/CvzDJ9m/blog2.png"
          projectName="Minimalist Blog"
          project="minimalist-blog"
          languages="Mongoose/EJS"
          description="This blog stores posts on a MongoDB database through using post requests on the /compose page. For each post, there is a dedicated page which continues the post in more detail."
          skills={["Post to my own RESTFUL API", "Minimalistic design", "Node JS + EJS"]}
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/94nT9zR/natours1.png"
          image2="https://i.ibb.co/4T6YHYC/natours2.png"
          projectName="Natours"
          project="natours"
          languages="HTML/SCSS"
          description="Natours was a portfolio project to learn SCSS which is a CSS framework and how to style beautiful sites without the help of bootstrap."
          skills={["Using SCSS", "Flexbox/grid layouts", "Responsiveness using media queries"]}
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
        <Modal
          image1="https://i.ibb.co/dWkRrz8/Screen-Shot-2021-10-19-at-7-48-45-pm.png"
          image2="https://i.ibb.co/zmp8GM8/Screen-Shot-2021-10-19-at-7-49-19-pm.png"
          project="art-gallery"
          projectName="Art Gallery"
          languages="React/AOS"
          description="This application showcases the use of animation on scroll library with a css grid layout for a 1 page gallery."
          skills={["Docs of AOS", "Grid layout", "Aesthetically positioning"]}
          link1="google.com"
          link2="https://artgalleryaos.netlify.app/"
          // references={[<ModalReference source="github.com" reference={`${<AiFillGithub />} Github Repo`}/>]}
        />
    </div>
  );
};
