import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export const ScrollTop = () => {

    return (
        <div className="scroll-top" id="scroll-top">
            <a href="/#section-about" className="scroll-top__btn"></a>
        </div>
    )
}
