import React, { Fragment, useState } from "react";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Subscribe } from "./subscribe/Subscribe";

// async function start() {
//   const querySnapshot = await getDocs(collection(db, "emails"));
//   querySnapshot.forEach((doc) => {
//     console.log(doc.data());
//   });
// }

// start();

const App = () => {
  const [isContact, setIsContact] = useState(true)
  return (
    <Router>
      <Fragment>
        <Switch>
          <Route exact path="/">
            <Home setIsContact={setIsContact}/>
            <Subscribe />
          </Route>
          <Route exact path="/contact/">
            <Contact isContact={isContact}/>
            <Subscribe />
          </Route>
          {/* <Modal /> */}
        </Switch>
      </Fragment>
    </Router>
  );
};

export default App;
