import React, {useState, Fragment} from "react";
// import { menuOpen } from "./Navbar";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ScrollTop } from "./scrollTop";

export const Homevid = ({isOpen}) => {
  const [homeStyles, setHomeStyles] = useState({})

  // if (isOpen) {
  //   setHomeStyles({
  //         visibility: "hidden",
  //         opacity: "0",
  //         width: "0",
  //         height: "0"
  //       })
  // }
  // if () {
  //   setHomeStyles({
  //     display: "none"
  //   })
  // }

  return (
    <div className="homevid" id="section-homevid">
      <video
        className="homevid__content"
        autoPlay
        muted
        src="david-develops-intro-2_.mp4"
        loop
        
      >
        <source src="david-develops-intro-2_.mp4" type="video/mp4" />
        Your browser is not supported!
    </video>
    {/* <div className="heading-container"> */}
    <div className="heading__intro">
      <h1 className="heading heading-primary">
          👋 Hi I'm David,
        </h1>
        <h1 className="heading heading-primary">
          A programmer.
        </h1>
        {/* <a href="#section-about" className="btn btn--change">
          <span className="btn btn--change__learn-more">Learn more &rarr;</span>
          <span className="btn btn--change__learn-more--change">Learn more &darr;</span>
      </a> */}
    {/* </div> */}
    </div>
    
  </div>
  );
};
