import React from 'react'
import { AiOutlineInstagram, AiFillGithub, AiOutlineTwitter, AiOutlineAreaChart } from "react-icons/ai";
import { Social } from './social/Social';
import { HiOutlineMail } from "react-icons/hi";
import { SiFiverr, SiGoogleanalytics } from "react-icons/si";
import { Value } from './values/Value';
import { VscDashboard } from "react-icons/vsc";
import { FiTarget } from "react-icons/fi";
import { FaTabletAlt } from "react-icons/fa";

export const About = () => {

    return (
        <div className="about" id="section-about" data-aos="fade-in" data-aos-duration="750">
        <div className="about__not-values">
        <figure className="about__shape-outline">
        <figure className="about__shape">
            <img src="images/david-cropped.jpg" alt="david" className="about__david-img" />
        </figure>
        </figure>
        
            <div className="about__description">
            <h2 className="heading heading-secondary">🤷‍♀️ Who is David?</h2>
            <p className="paragraph">I'm David - a <span className="paragraph paragraph-bold">full-stack web developer</span>, <span className="paragraph paragraph-bold">python programmer/tutor</span> and <span className="paragraph paragraph-bold">artificial-intelligence enthusiast</span> who loves designing beautiful UI which is fully responsive on all devices. </p>
            <p className="paragraph">Although I'm only fourteen, programming is definitely something I want to <span className="paragraph paragraph-bold">make a career out of</span>.</p>
            <p className="paragraph">When I'm not developing websites or reading the latest ML advancements, I'm probably training jiu jitsu or doing some form of sport (COVID willing!), not very hardcore gaming on a not very spec'd out computer, practising my violin or binging on netflix.</p>
            <p className="paragraph">I'm currently refining my react front-end development skills through courses on Udemy (this will be updated every so often!) and trying to <span className="paragraph paragraph-bold">build up a portfolio</span>.</p>
            </div>
        
        <Social />
        <div className="about__cta cta">
            <h3 className="cta__slogan">Ever wanted a tutor that makes learning python and web development fun?</h3>
            <div className="cta__chat-container">
                <a className="cta__chat" target="_blank" href="https://www.superprof.com.au/passionate-14yr-old-programmer-with-over-5yrs-experience-with-python-and-web-development-teaches-python-and-web-development-from.html">Look no further 👨‍🏫</a>
            </div>
        </div>
        </div>
        <div className="about__values">
            <Value valueIcon={<VscDashboard />} message="Fast websites with responsive images and minified code optimized for deployment." />
            <Value valueIcon={<FiTarget />} message="Simple yet sleek designs which are interactive and intuitive." />
            <Value valueIcon={<FaTabletAlt />} message="A fully responsive website with similar functionality across devices." />
            <Value valueIcon={<AiOutlineAreaChart />} message="High google search engine optimisation (SEO) so that people can actually find your website." />
        </div>
        

        </div>
    )
}
