import React from "react";
import { NewsletterSignup } from "../footer/NewsletterSignup";

export const Subscribe = () => {
  return (
    // <div className="subscribe">
    <div className="subscribe">
      <div className="modal" id="section-subscribe">
        <div className="modal__card modal__card--subscribe">
          <a href="#" className="modal__close">
            &times;
          </a>
          <div className="modal__subscribe__header-section">
            <h1 className="modal__subscribe__header heading heading-primary">
              Subscribe to Our Newsletter!
            </h1>
          </div>
          <h3 className="heading heading-secondary">Well... Why?</h3>
          <p className="paragraph">
            Our newsletter gives YOU a comprehensive look into everything tech.
            It's great with a cuppa (it's an aussie thing btw), maybe a blanket
            or two and your breakfast! We'll give YOU 4 of the latest and
            greatest pieces of news on tech that is worth your time and 4
            programming tips (for the programmers out there).
          </p>
          <NewsletterSignup />
        </div>
      </div>
    </div>
    // </div>
  );
};
