import React, { Fragment, useState } from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { SiFiverr } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";

export const Navbar = ({isContact}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [burgerClass, setburgerClass] = useState("navbar__burger-group")
  const [navbarStyles, setNavbarStyles] = useState({})
  const [screenStyles, setScreenStyles] = useState({})
  // let burgerStyles = {}
  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
    checkIsOpen()
  }

  const checkIsOpen = () => {
      if (isOpen) {
        setburgerClass("navbar__burger-group open")
        setNavbarStyles({
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "7000",
          borderBottom: "0.5rem solid #00adb5"
        })
        setScreenStyles({
          opacity: "1",
          visibility: "visible",
          width: "100%",
          height: "100vh"
        })
        if (isContact) {
          document.querySelector("body").style.overflow = "hidden";
        } else {
          document.querySelector("body").style.overflow = "hidden";
          document.getElementById("section-homevid").style.display = "none";
        }
        // document.getElementById("section-homevid").style.display = "none";
        // document.getElementById("section-homevid").style.width = "0";
        // document.getElementById("section-homevid").style.position = "static";
      } else {
        setburgerClass("navbar__burger-group")
        setScreenStyles({})
        if (isContact) {
          document.querySelector("body").style.overflow = "auto";
        } else {
          document.querySelector("body").style.overflow = "auto";
          document.getElementById("section-homevid").style.display = "block";
        }
      }
  }

  const handleLinkClick = () => {
    setIsOpen(false);
    checkIsOpen()
  }


  return (
    <Fragment>
    <nav className="navbar" id="navbar" style={navbarStyles}>
      <div onClick={handleBurgerClick} className={burgerClass}><div className="navbar__burger-btn"></div></div>
      <ul className="navbar__route-items">
        <li className="navbar__route-item"><a href="/#section-homevid" id="home" className="navbar__route-link">Home</a></li>
        <li className="navbar__route-item"><a href="/#section-about" className="navbar__route-link">About</a></li>
        <li className="navbar__route-item"><a href="/contact/" className="navbar__route-link">Contact</a></li>
        <li className="navbar__route-item"><a href="/#section-portfolio" className="navbar__route-link">Portfolio</a></li>
        <li className="navbar__route-item"><a href="/#section-skills" className="navbar__route-link">Skills</a></li>
      </ul>
      <div className="navbar__logo">
        <img src="https://i.ibb.co/D5P6hBd/david-develops.png" alt="Logo Image" className="navbar__logo-img" />
      </div>
      <ul className="navbar__social-icons">
        <li className="navbar__social-icon"><a href="https://www.instagram.com/au.david_develops/" target="_blank" className="navbar__social-icon-link--instagram"><AiOutlineInstagram /></a></li>
        <li className="navbar__social-icon"><a href="https://twitter.com/audaviddevelops" target="_blank" className="navbar__social-icon-link--twitter"><FaTwitter /></a></li>
        <li className="navbar__social-icon"><a href="https://www.superprof.com.au/passionate-14yr-old-programmer-with-over-5yrs-experience-with-python-and-web-development-teaches-python-and-web-development-from.html" target="_blank" className="navbar__social-icon-link--fiverr"><img className="navbar__social-icon-link--superprof" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKKzKYUhHM5mHO9AzVuHkaOAMsKfZr-I5O4tDXhQ1c4OdglIwjQ_CKGvsPpIoV2ATgtFI&usqp=CAU"/></a></li>
        <li className="navbar__social-icon"><a href="/#section-subscribe" className="navbar__social-icon-link navbar__social-icon-link--subscribe">💌 Subscribe</a></li>
      </ul>
      </nav>
      <div className="navbar__screen" style={screenStyles}>
        <ul className="navbar__screen-items" onClick={handleLinkClick}>
          <li className="navbar__screen-item"><a href="/#section-homevid" className="navbar__screen-link navbar__screen-link--home" >Home</a></li>
          <li className="navbar__screen-item"><a href="/#section-about" className="navbar__screen-link  navbar__screen-link--about" >About</a></li>
          <li className="navbar__screen-item"><a href="/contact/" className="navbar__screen-link  navbar__screen-link--contact" >Contact</a></li>
          <li className="navbar__screen-item"><a href="/#section-portfolio" className="navbar__screen-link  navbar__screen-link--portfolio" >Portfolio</a></li>
          <li className="navbar__screen-item"><a href="/#section-donate" className="navbar__screen-link  navbar__screen-link--donate" >Donate</a></li>
          <ul className="navbar__screen-socials">
            <li className="navbar__screen-item"><a href="https://www.instagram.com/au.david_develops/" target="_blank" className="navbar__screen-link navbar__screen-link-social  navbar__screen-link--insta"><AiOutlineInstagram /></a></li>
            <li className="navbar__screen-item"><a href="https://twitter.com/audaviddevelops" target="_blank" className="navbar__screen-link navbar__screen-link-social  navbar__screen-link--twitter"><FaTwitter /></a></li>
            <li className="navbar__screen-item"><a href="https://www.fiverr.com/" target="_blank" className="navbar__screen-link navbar__screen-link-social  navbar__screen-link--fiverr"><SiFiverr /></a></li>
          </ul>
          <li className="navbar__screen-item"><a href="/#section-subscribe" className="navbar__screen-link navbar__screen-link--subscribe">💌 Subscribe</a></li>
        </ul>
      </div>
      </Fragment>
    
  );
};

export default Navbar;
// export { menuOpen };

