import React from 'react'

export const Success = () => {
    return (
        <div className="modal" id="success">
            <div className="modal__card">
                <a href="/contact/" className="modal__close">&times;</a>
                <img src="https://media4.giphy.com/media/XreQmk7ETCak0/200.webp?cid=ecf05e47d6oj9pdg6dpoj1qqm6hvno1xl1brduede7aiuy8a&rid=200.webp&ct=g" alt="gif" />
                <h1 className="heading heading-primary">Success!</h1>
                <p className="paragraph">Thanks for submitting your inquiry. We'll try to get back to you as soon as possible.</p>
            </div>
        </div>
    )
}
