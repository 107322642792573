// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYR1OC4OiVUi192-UHHQqLeQqiyMJDHbY",
  authDomain: "react-freelance-app-c55c6.firebaseapp.com",
  projectId: "react-freelance-app-c55c6",
  storageBucket: "react-freelance-app-c55c6.appspot.com",
  messagingSenderId: "416123964508",
  appId: "1:416123964508:web:c12c3a79229bf91561ea99",
  measurementId: "G-5KB26MDFZS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore()
export default app
// export const auth = app.auth()
// const analytics = getAnalytics(app);