import React from "react";
import { Footer } from "../footer/Footer";
import {
  AiOutlineInstagram,
  AiFillGithub,
  AiOutlineTwitter,
  AiFillHome,
  AiFillContacts,
} from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { SiFiverr } from "react-icons/si";

export const Social = (props) => {
  return (
    <ul className="social-list">
      <li className="social-item">
        <a href="#" className="social-link">
          <span className="social-icon social-icon--insta">
            <AiOutlineInstagram />
          </span>{" "}
          <span className="social-acc">@au.david_develops</span>
        </a>
      </li>
      <li className="social-item">
        <a href="#" className="social-link">
          <span className="social-icon social-icon--git">
            <AiFillGithub />
          </span>{" "}
          <span className="social-acc">@david-developsAU</span>
        </a>
      </li>
      <li className="social-item">
        <a href="#" className="social-link">
          <span className="social-icon social-icon--twit">
            <AiOutlineTwitter />
          </span>{" "}
          <span className="social-acc">@daviddevelopsAU</span>
        </a>
      </li>
      <li className="social-item">
        <a href="#" className="social-link">
          <span className="social-icon social-icon--mail">
            <HiOutlineMail />
          </span>{" "}
          <span className="social-acc">daviddevelops.au@gmail.com</span>
        </a>
      </li>
    </ul>
  );
};
