import React from 'react'

export const Donate = () => {
    return (
        <div className="donate" id="section-donate">
            {/* <div className="donate__cta">
            <img src="https://image.cnbcfm.com/api/v1/image/106806533-1607102792308-gettyimages-1283072124-20200930_fooddrive2_sdil0383.jpeg?v=1607102809" alt="donate" className="donate__img"/>
            </div> */}
            <div className="donate__description">
                <h2 className="donate__heading">COA Sydney</h2>
                <p className="paragraph donate__paragraph">We are proud to support COA Sydney by donating our proceeds towards helping the elderly.</p>
            <div className="donate__options">
                <a href="https://www.coasydney.org/what-we-do" target="_blank" rel="noopener noreferrer" className="btn btn--learn-more donate__button">Learn more</a>
            </div>
            </div>
            
        </div>
    )
}
