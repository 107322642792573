import React from "react";
import Navbar from "../Navbar";
import { Footer } from "../footer/Footer";
import { AiOutlineInstagram, AiFillGithub, AiOutlineTwitter, AiFillHome, AiFillContacts } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { SiFiverr } from "react-icons/si";
import { Social } from "../social/Social";
import { Success } from "../success/Success";

export const Contact = ({isContact}) => {

  return (
    <div className="contact">
        <Navbar isContact={isContact}/>
      <div className="contact__heading-group fadeIn">
        <h1 className="heading heading-primary heading-primary--white heading-primary--contact contact__heading">
          👂 We'd Love to Hear From You.
        </h1>
      </div>
      <div className="contact__body">
      <form action="https://formsubmit.co/daviddevelops.au@gmail.com" method="POST" className="contact__form">
        <label htmlFor="fName" className="contact__label">
          First Name
        </label>
        <input id="fName" name="first-name" type="text" className="contact__input" placeholder="First Name" required/>
        <label htmlFor="lName" className="contact__label">
          Last Name
        </label>
        <input id="lName" name="last-name" type="text" className="contact__input" placeholder="Last Name" required/>
        <label htmlFor="email" className="contact__label">
          Email
        </label>
        <input id="email" type="email" name="email" className="contact__input" placeholder="Email Address" required/>
        <label htmlFor="website" className="contact__label">
          Website
        </label>
        <input id="website" name="website" type="text" className="contact__input" placeholder="Website"/>
        <label htmlFor="why" className="contact__label">
          Why are you contacting us
        </label>
        <input type="hidden" name="_next" value="/contact/#success"/>
        <input type="hidden" name="_autoresponse" value="Thanks for reaching out to us. We'll get back to you shortly!"/>
        <input type="hidden" name="_template" value="table"/>

        <select name="why" name="reason" id="why" className="contact__input" required>
          <option value="hire">You want to hire David</option>
          <option value="advice">You want some advice</option>
          <option value="hi">Just wanted to say hi</option>
          <option value="other">Other</option>
        </select>
        <label htmlFor="msg" className="contact__label">
          Message
        </label>
        <textarea
          name="msg"
          id="msg"
          cols="40"
          rows="10"
          className="contact__input contact__input--msg"
          placeholder="Message"
          required
        ></textarea>
        <button type="submit" className="btn btn--learn-more contact__btn">Send over</button>
      </form>
       <div className="contact__social-container">
          <h3 className="contact__label">Social</h3>
          <Social />
      </div>
      </div>
      <Success />
      <Footer />
    </div>
  );
};
