import React from "react";

export const Value = (props) => {
  return (
    <div className="about__values-anim" data-aos="flip-left">
      <div className="about__values-content">
        <div className="about__values-icon">{props.valueIcon}</div>
        <h5 className="about__values-message">{props.message}</h5>
      </div>
    </div>
  );
};
