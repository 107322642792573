import React, {Fragment} from 'react'
import Navbar from "../Navbar";
import { Homevid } from "../Homevid";
import { About } from "../About";
import { Skills } from "../skills/Skills.js";
import { Portfolio } from "../portfolio/Portfolio";
import { Donate } from "../donate/Donate";
import { Cta } from "../cta/Cta";
// import { Sidebar } from "./sidebar/Sidebar";
import { Footer } from "../footer/Footer";
import { Top } from '../Top/Top';
import { ScrollTop } from '../scrollTop';

export const Home = () => {
    return (
        <Fragment>
            <Top />
            {/* <Sidebar /> */}
            <Portfolio />
            <Donate />
            <Cta />
            <ScrollTop />
            <Footer />
        </Fragment>
    )
}
