import React from "react";
import { AiFillGithub } from "react-icons/ai";
import { VscGlobe } from "react-icons/vsc";

export const Modal = (props) => {
  return (
    <div id={props.project} className={`modal modal--${props.project}`}>
      <div className="modal__card">
      <a href={`#${props.project}-card`} className="modal__close">
        &times;
      </a>
        <div className={`modal__images modal__images--${props.project}`}>
          <img src={props.image1} alt="image 1" class="modal__images--image1" />
          <img src={props.image2} alt="image 2" class="modal__images--image2" />
        </div>
          <div className="modal__header portfolio__header portfolio__header--modal">
            <h4 className="modal__header__project-name heading-tertiary">
              {props.projectName}
            </h4>
            <span className="paragraph paragraph-bold">{props.languages}</span>
          </div>
          <div className="modal__description">
            <h4 className="modal__description-heading paragraph paragraph-bold">
              What is {props.projectName}?
            </h4>
            <p className="paragraph">{props.description}</p>
          </div>
          <div className="modal__outline">
            <h4 className="modal__description-heading paragraph paragraph-bold">
              Skills Acquired:
            </h4>
            <ul className="modal__skills">
              {props.skills.map((skill) => (
                <li className="modal__skills__skill paragraph">{skill}</li>
              ))}
            </ul>
          </div>
          <ul className="modal__references">
              <li className="modal__references__reference paragraph">
                <a href={props.link1} className="modal__references__reference-link" target="_blank" rel="noopener noreferrer" >
                  <span className="modal__references__reference-icon"><AiFillGithub /></span> Github Repository
                </a>
              </li>
              <li className="modal__references__reference paragraph">
                <a href={props.link2} className="modal__references__reference-link" target="_blank" rel="noopener noreferrer" >
                <span className="modal__references__reference-icon"><VscGlobe /></span> Webpage link
                </a>
              </li>
            </ul>
      </div>
    </div>
  );
};
