import React from 'react'

export const Cta = () => {
    return (
        <div className="cta">
            <h3 className="cta__slogan" data-aos="slide-down" data-aos-offset="200" data-aos-duration="750">Got an idea but don't know where to start?</h3>
            <div className="cta__chat-container" data-aos="slide-up" data-aos-offset="200" data-aos-duration="750">
                <a className="cta__chat" href="/contact/">Let's chat 👨‍💻</a>
            </div>
        </div>
    )
}
