import React, { Fragment } from "react";

export const Project = (props) => {
  return (
    <Fragment>
      <div id={`#${props.project}-card`} data-aos="zoom-in" className={`portfolio__card portfolio__card--${props.cardNum}`}>
      <div className="portfolio__card-details">
      <div className="portfolio__header u-margin-bottom-md">
          <h4 className="heading-tertiary">{props.projectName}</h4>
          <span className="paragraph paragraph-bold">{props.languages}</span>
        </div>
        <div className="portfolio__description">
          <a href={`#${props.project}`} className="btn btn--learn-more">
            Learn more
          </a>
        </div>
      </div>
        
      </div>
    </Fragment>
  );
};
