import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
const axios = require("axios");
require("dotenv").config();

export const NewsletterSignup = (props) => {
  const [emailEntry, setEmailEntry] = useState("");
  const [name, setName] = useState("");

  const handleEmailChange = (event) => {
    setEmailEntry(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const docRef = await addDoc(collection(db, "emails"), {
    //   fullName: name,
    //   email: emailEntry,
    // });
    // console.log("Document written with ID: ", docRef.id);
    // try {
    axios
      .post("http://localhost:4000/sendEmail", { name, emailEntry })
      .then((response) => console.log(response));
    // } catch (error) {
    //   console.log(error);
    // }
    setEmailEntry("");
    setName("");
  };

  function onSubmit(token) {
    document.getElementById("demo-form").submit();
  }

  return (
    <Fragment>
      {/* <form
        onSubmit={handleSubmit}
        // action="/sendEmail"
        // method="POST"
      >
        <input
          className="footer__newsletter-signup-entry"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          placeholder="YOUR EMAIL"
          onChange={handleEmailChange}
          value={emailEntry}
          required
        />
        <button className="footer__newsletter-signup-btn" type="submit">
          Subscribe
        </button>
      </form> */}

      {/* <div id="mc_embed_signup"> */}
      <form
        action="https://daviddevelops.us5.list-manage.com/subscribe/post?u=a202fb6bd85fd45ddd5969bf1&amp;id=86bc61f1c6"
        method="post"
        // id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        // class="validate"
        target="_blank"
        id="demo-form"
        novalidate
      >
        {/* <label for="mce-EMAIL">Subscribe</label> */}
        <input
          type="email"
          // value=""
          name="EMAIL"
          className="footer__newsletter-signup-entry"
          id="mce-EMAIL"
          placeholder="Email Address"
          required
        />
        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        {/* <div aria-hidden="true"> */}
        <input
          type="text"
          name="b_a202fb6bd85fd45ddd5969bf1_86bc61f1c6"
          tabindex="-1"
          value=""
          type="hidden"
        />
        <button
          className="footer__newsletter-signup-btn"
          data-sitekey="6LcwwN8cAAAAALmrvZ-jam7W9PSrMR_7a090KjzK"
          data-callback="onSubmit"
          data-action="submit"
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
        >
          Subscribe
        </button>
        {/* </div> */}
      </form>
      {/* </div> */}
    </Fragment>
  );
};
