import React from "react";
import {
  AiOutlineInstagram,
  AiFillGithub,
  AiOutlineTwitter,
  AiFillHome,
  AiFillContacts,
} from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { SiFiverr } from "react-icons/si";
import { BsFillBriefcaseFill, BsFillInfoCircleFill } from "react-icons/bs";
import { BiDonateBlood } from "react-icons/bi";
import { GrContactInfo } from "react-icons/gr";
import { NewsletterSignup } from "./NewsletterSignup";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__main-section">
          <h4 className="footer__header">Where to find us</h4>
          <ul className="footer__find-us-list">
            <li className="footer__find-us__location">Sydney, Australia</li>
            <li className="footer__find-us__social-text footer__find-us__social-text--email">
              <a href="#" className="footer__find-us__social-link">
                <span className="footer__icons">
                  <HiOutlineMail />
                </span>
                daviddevelops.au@gmail.com
              </a>
            </li>
            <li className="footer__find-us__social-text">
              <a href="#" className="footer__find-us__social-link">
                <span className="footer__icons">
                  <AiOutlineInstagram />
                </span>
                @au.david_develops
              </a>
            </li>
            <li className="footer__find-us__social-text">
              <a href="#" className="footer__find-us__social-link">
                <span className="footer__icons">
                  <AiFillGithub />
                </span>
                @david-developsAU
              </a>
            </li>
            <li className="footer__find-us__social-text">
              <a href="#" className="footer__find-us__social-link">
                <span className="footer__icons">
                  <AiOutlineTwitter />
                </span>
                @daviddevelopsAU
              </a>
            </li>
          </ul>
        </div>

        <div className="footer__main-section">
          <h4 className="footer__header">Quick Links</h4>
          <ul className="footer__quick-links">
            <li className="footer__quick-links__redirect-text">
              <a href="/#section-homevid" className="footer__quick-links__redirect-text-link">
                <span className="footer__icons">
                  <AiFillHome />
                </span>
                Home
              </a>
            </li>
            <li className="footer__quick-links__redirect-text">
              <a href="/#section-about" className="footer__quick-links__redirect-text-link">
                <span className="footer__icons">
                  <BsFillInfoCircleFill />
                </span>
                About
              </a>
            </li>
            <li className="footer__quick-links__redirect-text">
              <a href="/contact" className="footer__quick-links__redirect-text-link">
                <span className="footer__icons">
                  <AiFillContacts />
                </span>
                Contact us
              </a>
            </li>
            <li className="footer__quick-links__redirect-text">
              <a href="/#section-portfolio" className="footer__quick-links__redirect-text-link">
                <span className="footer__icons">
                  <BsFillBriefcaseFill />
                </span>
                Portfolio
              </a>
            </li>
            <li className="footer__quick-links__redirect-text">
              <a href="/#section-skills" className="footer__quick-links__redirect-text-link">
                <span className="footer__icons">
                  <BiDonateBlood />
                </span>
                Skills
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__main-section--logo">
          <img
            src="https://i.ibb.co/D5P6hBd/david-develops.png"
            alt="logo"
            className="footer__logo"
          />
        </div>
      </div>
      <div className="footer__newsletter-signup">
        <h4 className="footer__header">🚨 Sign Up to Our Newsletter 🚨</h4>
        <h4 className="footer__sub-header">
          Everything tech straight to your inbox
        </h4>
        <NewsletterSignup />


      </div>
      <a
        href="https://twitter.com/audaviddevelops?ref_src=twsrc%5Etfw"
        class="twitter-follow-button"
        data-show-count="false"
      >
        Follow @audaviddevelops
      </a>
      <a
        href="https://twitter.com/intent/tweet?screen_name=audaviddevelops&ref_src=twsrc%5Etfw"
        class="twitter-mention-button"
        data-show-count="false"
      >
        Tweet to @audaviddevelops
      </a>
      <p className="footer__copyright-msg">
        &copy; 2021 David Tabachnik. All rights reserved. Use this website for
        inspiration with credit to the original owner where appropriate.
      </p>
      <p className="footer__copyright-msg">
        David Tabachnik - Freelance web developer/ML beginner based in Sydney.
      </p>
    </footer>
  );
};
